"use client";

import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import {
  Divider,
  alpha,
  styled,
  Box,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Subscribe } from "./Subscribe";
import Logo from "../../public/Logo.png";
import RoundedButton from "./RoundedButton";
import styles from "./page.module.css";
import { LuAlignJustify } from "react-icons/lu";

const HeaderStyled = styled("header")`
  align-items: center;
  background-color: black;
  display: flex;
  gap: 8px;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const NavLinkStyled = styled(Link)`
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  transition: 300ms ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    color: ${({ theme }) => alpha(theme.palette.common.white, 0.7)};
  }
`;

const StyledPopoverContent = styled(Box)`
  border-radius: 20px;
  left: 50%;
  padding: 16px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;

  @media (max-width: 786px) {
    padding: 0 10px; /* Add margin on mobile */
    width: 90%;
  }
`;

const StyledOptionsPopoverContent = styled(Box)`
  border-radius: 20px;
  left: 50%;
  padding: 16px;
  position: absolute;
  top: 20%;
  transform: translate(-50%, -50%);
  width: 400px;

  @media (max-width: 786px) {
    padding: 0 10px; /* Add margin on mobile */
    width: 90%;
  }
`;

const Nav = styled("nav")`
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

const LogoImage = styled(Image)`
  height: 40px; /* Adjust the height as needed */
  cursor: pointer;
  margin-right: 8px; /* Adjust the margin as needed */
`;

export const Header = () => {
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);

  const handleOpenSubscribe = () => {
    setOpenSubscribe(true);
    setOpenOptions(false); // Close options modal if open
  };

  const handleCloseSubscribe = () => setOpenSubscribe(false);

  const handleOpenOptions = () => {
    setOpenOptions(true);
    setOpenSubscribe(false); // Close subscribe modal if open
  };

  const handleCloseOptions = () => setOpenOptions(false);

  return (
    <HeaderStyled>
      <NavLinkStyled href="/" passHref>
        <LogoImage src={Logo} alt="Home" width={40} height={40} />
        <span className={styles["hide-on-phone"]} style={{ color: 'white' }}>Nikola Knezevic</span>
        </NavLinkStyled>
      <Nav>
        <Box className={styles["hide-on-phone"]}>
          <NavLinkStyled href="/blogs" passHref>
          <span style={{ color: 'white' }}>Blogs</span>
          </NavLinkStyled>
        </Box>
        <Box className={styles["hide-on-phone"]}>
          <NavLinkStyled href="/sponsorship" passHref>
          <span style={{ color: 'white' }}>Sponsorship</span>
          </NavLinkStyled>
        </Box>
        <Divider
          flexItem
          variant="middle"
          orientation="vertical"
          className="hide-on-mobile"
        />

        <RoundedButton
          color="primary"
          variant="contained"
          disableElevation
          disableRipple
          onClick={handleOpenSubscribe}>
          <Typography>Subscribe</Typography>
        </RoundedButton>

        <Modal open={openSubscribe} onClose={handleCloseSubscribe}>
          <StyledPopoverContent bgcolor="background.paper">
            <Box display="flex" justifyContent="flex-end">
              <IconButton aria-label="close" onClick={handleCloseSubscribe}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box mb={2} />

            <Subscribe
              titleSize={20}
              textSize={16}
              inputSize={42}
              gap={16}
              title="Level Up Your .NET Skills"
              text="Stay tuned for valuable insights each week!"
              onSubscribed={handleCloseSubscribe}
              inputWidth={250}
            />

            <Box mb={3} />
          </StyledPopoverContent>
        </Modal>

        <Box className={styles["hide-on-pc"]}>
          <IconButton onClick={handleOpenOptions} color="inherit">
            <LuAlignJustify color="white"/>
          </IconButton>
        </Box>
        <Modal open={openOptions} onClose={handleCloseOptions}>
  <StyledOptionsPopoverContent bgcolor="background.paper" display="flex" flexDirection="column" alignItems="center">
    <Box mb={2} />
    <NavLinkStyled href="/blogs" passHref onClick={handleCloseOptions}>
      <Typography color={"black"} fontSize="1.3rem" textAlign="center">Blogs</Typography>
    </NavLinkStyled>
    <NavLinkStyled href="/sponsorship" passHref onClick={handleCloseOptions}>
      <Typography mt={2} color={"black"} fontSize="1.3rem" textAlign="center">Sponsorship</Typography>
    </NavLinkStyled>
    <Divider
      flexItem
      variant="middle"
      orientation="horizontal"
      style={{ margin: '12px 0' }} // Adjust this value for more or less space
    />
    <NavLinkStyled href="/" passHref onClick={handleCloseOptions}>
      <Typography color={"#057ACC"} fontSize="1.3rem" textAlign="center" fontWeight="bold">Subscribe</Typography>
    </NavLinkStyled>
    <Box mb={2} />
  </StyledOptionsPopoverContent>
</Modal>

      </Nav>
    </HeaderStyled>
  );
};

export default Header;
